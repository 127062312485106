<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 uppercase text-2xl font-semibold py-2">
        Rapports d'activité
      </div>
    </PageHeader>
    <div class="flex flex-col font-Inter p-5">
      <div class="bg-white shadow p-5">
        <div class="rapport">
          <div class="rapport_row">
            <div class="rapport_item">
              <div class="text-xl font-semibold mb-3">
                <i class="icon el-icon-s-data mr-2"></i>Ventes
              </div>
              <router-link to="/rapports/article" class="py-2 text-blue"
                >Vente par articles</router-link
              >
              <router-link to="/rapports/customer" class="py-2 text-blue"
                >Vente par client</router-link
              >
              <router-link to="/rapports/revenue" class="py-2 text-blue"
                >Chiffre d'affaire</router-link
              >
            </div>

            <div class="rapport_item">
              <div class="text-xl font-semibold mb-3">
                <i class="icon el-icon-s-data mr-2"></i>Achat et depense
              </div>
              <router-link to="/rapports/purchase/article" class="py-2 text-blue"
                >Achat par articles</router-link
              >
              <router-link to="/rapports/purchase/supplier" class="py-2 text-blue"
                >Achat par fournisseur</router-link
              >
              <router-link to="#" class="py-2 text-blue"
                >Rapport des depenses</router-link
              >
            </div>
            <div class="rapport_item">
              <div class="text-xl font-semibold mb-3">
                <i class="icon el-icon-s-data mr-2"></i>Stock
              </div>
              <router-link to="#" class="py-2 text-blue"
                >Variation de stock</router-link
              >
              <router-link to="#" class="py-2 text-blue"
                >Entrée et Sortie</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
export default {
  name: "RAPPORT",
  components: { PageHeader },
  data() {
    return {
      activeName: "SALES",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.rapport {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .rapport_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  .rapport_item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  /* 
  .rapport_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    flex: 1;
    height: 150px;
    margin-left: 10px;
    border: 1px solid $primary;
    border-radius: 6px;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    cursor: pointer;
    .title,
    .icon {
      color: $primary;
    }
    .icon {
      font-size: 30px;
    }
    .title {
      margin-top: 5px;
      font-size: 16px;
    }
  } 
  .rapport_item:hover {
    border: 1px solid $primary;
    background: $primary;
  }
  .rapport_item:hover * {
    color: $white;
  } */
}
</style>
